<template>
  <div class="user-role-bind-log">
    <app-query-bar
      :queryConfig="queryConfig"
      v-model="queryData"
      @onSearch="fetchLogs" />
    <app-export-bar
      @onExport="onExport"
      @onExportAll="onExportAll" />
    <app-data-table
      :columns="LOG_COLUMNS"
      :data="logs"
      v-model="queryData"
      @onPageConfigChange="fetchLogs" />
  </div>
</template>

<script>
import { LOG_COLUMNS } from './constants';
import UserRoleBindLogApi from './user-role-bind-log.api';
import DataTransferUtil from '@/utils/data-transfer.util';

export default {
  name: 'user-role-bind-log',
  data() {
    return {
      LOG_COLUMNS,
      queryConfig: [
        {
          fieldName: 'nickName',
          name: '用户名',
        },
        {
          fieldName: 'mobile',
          name: '手机号',
        },
        {
          fieldName: 'optNickName',
          name: '操作人',
        },
      ],
      queryData: {
        page: 1,
        limit: 10,
        count: 0,
        nickName: '',
        mobile: '',
        optNickName: '',
      },
      logs: [],
    };
  },
  created() {
    this.fetchLogs();
  },
  methods: {
    async fetchLogs() {
      const {
        page,
        limit,
        nickName,
        mobile,
        optNickName,
      } = this.queryData;
      const {
        count = 0,
        results = [],
      } = await UserRoleBindLogApi.getLogs(
        page,
        limit,
        nickName,
        optNickName,
        mobile,
      );
      this.logs = results;
      this.$set(this.queryData, 'count', count);
    },
    async onExport() {
      const {
        page,
        limit,
        nickName,
        mobile,
        optNickName,
      } = this.queryData;
      const key = await UserRoleBindLogApi.export({
        page,
        limit,
        nick_name: nickName,
        opt_nick_name: optNickName,
        mobile,
      });
      await DataTransferUtil.downloadFileBySecretKey(key);
    },
    async  onExportAll() {
      const key = await UserRoleBindLogApi.export();
      await DataTransferUtil.downloadFileBySecretKey(key);
    },
  },
};
</script>

<style scoped>

</style>
