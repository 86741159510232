import axios from '@/plugins/axios';

class UserRoleBindLogApi {
  static getLogs(
    page = 1,
    limit = 10,
    nickName = '',
    optNickName = '',
    mobile = '',
  ) {
    return axios({
      url: '/api/sys/log/user/role/bind/page',
      data: {
        page,
        limit,
        nick_name: nickName,
        opt_nick_name: optNickName,
        mobile,
      },
      method: 'post',
      headers: {
        meta: {
          skipSuccess: true,
          desc: '查询用户角色绑定日志',
        },
      },
    });
  }

  static export(data) {
    return axios({
      url: '/api/sys/log/user/role/bind/export',
      method: 'post',
      data,
      headers: {
        meta: {
          skipSuccess: true,
          desc: '导出',
        },
      },
    });
  }
}

export default UserRoleBindLogApi;
