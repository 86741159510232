export const LOG_COLUMNS = [
  {
    title: '操作内容',
    key: 'content',
    minWidth: 180,
  },
  {
    title: '用户名',
    key: 'nick_name',
    minWidth: 140,
  },
  {
    title: '手机号',
    key: 'mobile',
    minWidth: 130,
  },
  {
    title: '操作人',
    key: 'opt_nick_name',
    minWidth: 140,
  },
  {
    title: '操作时间',
    key: 'create_time',
    minWidth: 180,
  },
];
